.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 250px;
  background-image: linear-gradient(0, #2a156f 0%, #ad86d4 100%);
  width: 800px;
  border-radius: 15px;
  z-index: 0;
}

.quote {
  padding: 60px 110px;
  color: white;
  font-size: 32px;
  text-align: center;
  z-index: 1;
}

.line {
  width: 680px;
  height: 7.5px;
  border-radius: 5px;
  background: rgb(188, 169, 255);
  z-index: 1;
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px;
  z-index: 1;
}

.author {
  color: white;
  font-size: 20px;
  font-weight: 500;
  z-index: 1;
}

.icons {
  display: flex;
  gap: 40px;
  z-index: 1;
}

.icons img {
  cursor: pointer;
  height: 36px;
  z-index: 1;
}

@media (max-width: 786px) {
  .container {
    width: 90%;
    margin-top: 150px;
    align-items: center;
  }

  .quote {
    padding: 40px 20px;
    font-size: 24px;
  }

  .line {
    width: 90%;
    height: 5px;
  }

  .bottom {
    flex-direction: column;
    gap: 20px;
    height: 150px;
  }

  .author {
    font-size: 18px;
  }

  .icons {
    gap: 100px;
  }

  .icons img {
    height: 28px;
  }

  .base {
    margin: 0 40px 0 40px;
  }
}
